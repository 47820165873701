<template>
    <div
      style="height: 60px;box-shadow: 0 6px 12px 0 rgba(220, 220, 220, 0.25);display: flex;flex-direction: row;align-items: center"
      class="back-common">
      <div style="width: 1200px;margin: 0 auto" class="top-four">
        <div v-for="(item,index) in list_items" :key="index"
             :class="['top-four-span',{'item_span':isItemActive === index}]" @click="choose_item(index)">{{ item.name }}
        </div>

      </div>
    </div>
</template>

<script>
import logo from "@/assets/logo.png";
import { getNavConfig, getRouteConfig } from "@/plugins/route";

export default {
  name: "navigation",
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to, from) {
        let pageId = to.params.pageId;
        getRouteConfig().forEach((item, i) => {
          if (item.name === to.name) {
            this.isItemActive = item.navIndex;
          }
        });

      }
    }
  },

  data() {
    return {
      logo: logo,
      isItemActive: 0,
      list_items: getNavConfig()
    };
  },
  mounted() {
    // this.$forceUpdate();
    // this.getIndexShowDetail();
    // if(!this.dominCookie){
    //   warnToast("登陆失效，请重新登录")
    // }
    // let path = this.$route.params.pageId || this.$route.name;
    // path = '/home/' + path;
    // let match = getRouteConfig().filter(x => x.path === path);
    // console.log(match)
    // this.isItemActive =match[0].navIndex > 8? 0:match[0].navIndex;
    // // this.$emit("changeIndex", this.isItemActive);
  },

  methods: {
    choose_item(index) {
      this.isItemActive = index;
      let nav = getRouteConfig().filter(x => x.navIndex === index);
      this.$router.push({
        path: nav[0].path
      });
    },
  }
};
</script>

<style scoped lang="scss">
.top-four {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.item_span {
  color: rgba(0, 64, 153, 1) !important;
  border-bottom: 2px solid rgba(0, 64, 153, 1);
}

.top-four-span {
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 400;
  color: #333333 ;
}

</style>
