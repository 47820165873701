<template>
  <div class="content">
    <IndexHeader></IndexHeader>
    <Navigation></Navigation>
    <div class="warp">
      <iframe class="iframe_box" :src="url" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="yes"></iframe>
    </div>
    <!-- <FooterComponent class = "foot"></FooterComponent> -->
  </div>
</template>

<script>
  import Navigation from "@/components/nav";
  import IndexHeader from "@/components/index/indexHeader";
  // import FooterComponent from "@/components/index/homePageComponent/footerComponent";
  export default {
    name: "qxt_result",
    components: {IndexHeader,Navigation},
    data() {
      return {
        url: ""
      }
    },
    mounted(){
      this.url = this.$route.params.url
    },
  }
</script>

<style lang="scss" scoped>
  .content{
    width: 100%;
    height: 100%;
  }
  .iframe_box{
    width: 100%;
    height: 100%;
  }
  .warp{
    width: 100%;
    position: fixed;
    top:120px;
    bottom: 0px;
  }
  /*body::-webkit-scrollbar { width: 0px; }*/
</style>